<template>
  <v-card id="recurring-reservation-list">
    <v-row
      class="px-2 ma-0 text-center"
    >
      <v-col
        cols="12"
        sm="12"
        md="3"
      >
        <v-text-field
          v-model="searchQueryRecurringReservations"
          :placeholder="t('search')"
          outlined
          hide-details
          dense
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-divider />

    <v-data-table
      :headers="computedTableColumnsRecurringReservations"
      :items="dataListTableRecurringReservations"
      item-key="id"
      :options.sync="optionsRecurringReservations"
      :loading="loadingRecurringReservations"
      :loading-text="t('table.loading_text')"
      :no-data-text="t('table.no_data')"
      :header-props="{sortByText: t('table.sort_by')}"
      :footer-props="{itemsPerPageText: t('table.item_page')}"
    >
      <template #[`item.start_time`]="{item}">
        <span
          class="text-capitalize"
        >
          {{ `${item.start_time} - ${item.end_time}` }}
        </span>
      </template>

      <template #[`item.weekdays`]="{item}">
        <span
          class="text-capitalize"
        >
          {{ resolveWeekdays(item.weekdays) }}
        </span>
      </template>

      <template #[`item.status`]="{item}">
        <v-chip
          small
          :color="resolveStatusVariant(item.status)"
          :class="`${resolveStatusVariant(item.status)}--text`"
          class="v-chip-light-bg font-weight-semibold text-capitalize"
        >
          {{ item.status_str }}
        </v-chip>
      </template>

      <template #[`item.actions`]="{item}">
        <v-tooltip
          bottom
        >
          <template #activator="{ on: tooltip, attrs }">
            <v-icon
              size="20"
              class="me-2"
              color="info"
              v-bind="attrs"
              v-on="{ ...tooltip }"
              @click="onShow(item)"
            >
              {{ icons.mdiEyeOutline }}
            </v-icon>
          </template>
          <span>{{ t('tooltip.detail') }}</span>
        </v-tooltip>

        <v-tooltip
          v-if="item.status === 'A'"
          bottom
        >
          <template #activator="{ on: tooltip, attrs }">
            <v-icon
              size="20"
              class="me-2"
              color="error"
              v-bind="attrs"
              v-on="{ ...tooltip }"
              @click="onCancel(item.id)"
            >
              {{ icons.mdiCancel }}
            </v-icon>
          </template>
          <span>{{ t('tooltip.cancel') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiPlus,
  mdiCancel,
  mdiCalendarSync,
  mdiEyeOutline,
  mdiCalendarCheckOutline,
  mdiClipboardTextSearchOutline,
} from '@mdi/js'
import { ref, computed, onMounted, getCurrentInstance } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'

import { cancelRecurringReservation } from '@api'

// sidebar
import { avatarText } from '@core/utils/filter'
import { error, success } from '@core/utils/toasted'
import Swal from 'sweetalert2'

import useReservationList from './useReservationList'

export default {
  setup() {
    const { t, tInContext } = useUtils()
    const { router, route } = useRouter()
    const vm = getCurrentInstance().proxy

    const {
      dataListTableRecurringReservations,
      computedTableColumnsRecurringReservations,
      totalDataListTableRecurringReservations,
      searchQueryRecurringReservations,
      statusRecurringReservations,
      statusPaymenRecurringReservations,
      fieldRecurringReservations,
      modalFromRecurringReservations,
      modalToRecurringReservations,
      dateFromRecurringReservations,
      dateToRecurringReservations,
      loadingRecurringReservations,
      descSortRecurringReservations,
      optionsRecurringReservations,

      filters,
      maxDate,
      fieldsOptions,
      configFacility,

      fetchFields,
      fetchRecurringReservations,
      resolveStatusVariant,
      resolveStatusPaymentVariant,
      resolveStatusText,
      resolveWeekdays,
      hasPermission,
      setFilters,
      setOptions,
      getFilterBySubmodule,
    } = useReservationList()

    const statusOptions = computed(() => [
      { text: t('status.confirmed'), value: 'F' },
      { text: t('status.cancelled'), value: 'C' },
    ])

    const statusPaymentOptions = computed(() => [
      { text: t('status.pending'), value: 'N' },
      { text: t('status.hold'), value: 'H' },
      { text: t('status.paid'), value: 'P' },
    ])

    const tab = ref(0)
    const tabs = ref([
      {
        title: 'Bookings',
        icon: mdiClipboardTextSearchOutline,
        disabled: false,
        hide: false,
      },
      {
        title: 'Recurring Bookings',
        icon: mdiCalendarCheckOutline,
        disabled: false,
        hide: false,
      },
    ])

    const onShow = item => {
      router.push({
        name: 'views-recurring-reservations-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 2,
        },
      })
    }

    const onCancel = async id => {
      const customTitle = `${tInContext('questions.are_you_sure', vm)}`
      const customConfirm = `${tInContext('reservations.delete_all_pending', vm)}`
      Swal.fire({
        title: customTitle,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: customConfirm,
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await cancelRecurringReservation(id)
          if (response.ok) {
            success(response.message)
            fetchRecurringReservations()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    onMounted(async () => {
      const recurringReservationFilters = getFilterBySubmodule('views-recurring-reservation-list', 'recurringReservations')

      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      await fetchFields(configFacility.value)
      await fetchRecurringReservations()

      if (recurringReservationFilters.search) searchQueryRecurringReservations.value = recurringReservationFilters.search
    })

    return {
      tab,
      tabs,
      statusOptions,
      statusPaymentOptions,
      dataListTableRecurringReservations,
      computedTableColumnsRecurringReservations,
      totalDataListTableRecurringReservations,
      searchQueryRecurringReservations,
      statusRecurringReservations,
      statusPaymenRecurringReservations,
      fieldRecurringReservations,
      modalFromRecurringReservations,
      modalToRecurringReservations,
      dateFromRecurringReservations,
      dateToRecurringReservations,
      loadingRecurringReservations,
      descSortRecurringReservations,
      optionsRecurringReservations,

      filters,
      maxDate,
      fieldsOptions,

      onShow,
      onCancel,
      avatarText,
      resolveStatusVariant,
      resolveStatusPaymentVariant,
      resolveStatusText,
      resolveWeekdays,
      fetchRecurringReservations,
      hasPermission,
      setFilters,
      setOptions,

      // i18n
      t,

      // icons
      icons: {
        mdiCancel,
        mdiPlus,
        mdiEyeOutline,
        mdiCalendarSync,
      },
    }
  },
}
</script>
